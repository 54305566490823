﻿$color-hero-is-dark: #282c34;
$color-nav-bar: white;
$color-blue-highlight: #61dafb;
$color-green-highlight: #01754F;
$navbar-height: 66px;

$theme-primary-body-bg-color: white;
$theme-secondary-body-bg-color: #353941;
$theme-secondary-code-bg-color: $color-nav-bar;

@mixin removeNavBarPadding {
  @media all and (max-width: 1099px) {
    @content;
  }
}

@mixin reduceNavBarPadding {
  @media all and (max-width: 1472px) and (min-width: 1100px) {
    @content;
  }
}

@mixin renderMobileDevice {
  @media all and (max-width: 449px) {
    @content;
  }
}

@mixin renderTabletDevice {
  @media all and (max-width: 769px) and (min-width: 450px) {
    @content;
  }
}

@mixin renderTabletNavView {
  @media all and (max-width: 950px) and (min-width: 600px) {
    @content;
  }
}

@mixin renderMobileNavView {
  @media all and (max-width: 599px) {
    @content;
  }
}

@mixin centerStackedColumnContent {
  @media all and (max-width: 769px) {
    @content;
  }
}