.footer {
  padding: 0% !important;
}


.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  /* height: 35px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: black;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

/* .footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
} */

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 5px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
