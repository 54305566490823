
.get-in-touch .background-image {
    background-image: url('../assets/img/ContactUs/ContactUsBg.jpg');
    background-size: cover;
    background-position: center;
    padding: 40px 0;
  }
  
  .get-in-touch .container {
    background-color: #e0e0e0; /* Gray background for the container */
    padding: 20px;
    margin: 0 auto;
    margin-top: 12%;
    margin-bottom: 12%;
    max-width: 1000px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .get-in-touch .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .get-in-touch .header-left {
    flex: 1;
    text-align: left;
    font-weight: bold;
    font-size: 32px;
  }
  
  .get-in-touch .header-center {
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }
  
  .get-in-touch .header-right {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .get-in-touch .header-right .social-icons {
    display: flex;
    gap: 10px;
    margin-left: 10px;
  }
  
  .get-in-touch .social-icons .btn-social {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .get-in-touch .social-icons .btn-social:hover {
    color: #01754f;
  }
  
  .get-in-touch .social-icon {
    font-size: 24px;
  }
  
  .get-in-touch .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .get-in-touch .form-card {
    background-color: #f5f5f5; /* Light gray background for the form */
    padding: 20px;
    border-radius: 8px;
    flex: 1;
    margin-right: 20px;
  }
  
  .get-in-touch .form-group {
    margin-bottom: 15px;
  }
  
  .get-in-touch .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .get-in-touch .form-group input,
  .get-in-touch .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .get-in-touch .form-group textarea {
    height: 100px;
  }
  
  .get-in-touch .send-message {
    background-color: #01754f; /* Button background color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .get-in-touch .send-message:hover {
    background-color: #01754f;
  }
  
  .get-in-touch .map-card {
    background-color: #e0e0e0;
    
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .get-in-touch .map-card img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .get-in-touch .map-card p {
    font-weight: bold;
    font-size: 18px;
  }
  

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1000;
  }
  
  .loader-image {
    width: 100px; /* Adjust the size as needed */
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  